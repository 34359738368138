import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../../utils/firebaseconfig';
import { format, subDays, startOfMonth, endOfMonth } from 'date-fns';
import { Pie, Bar, Line } from 'react-chartjs-2';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';

// Import necessary Chart.js components
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from 'chart.js';

// Register the components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
);

const Analytics = () => {
  const [selectedRange, setSelectedRange] = useState('today');
  const [analyticsData, setAnalyticsData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);

  useEffect(() => {
    if (selectedRange === 'customRange' && customStartDate && customEndDate) {
      fetchAnalyticsData();
    } else if (selectedRange !== 'customRange') {
      fetchAnalyticsData();
    }
  }, [selectedRange, customStartDate, customEndDate]);

  const fetchAnalyticsData = async () => {
    try {
      const now = new Date();
      let startDate;
      let endDate;
  
      if (selectedRange === 'allData') {
        startDate = null;
        endDate = null;
      } else if (selectedRange === 'customRange' && customStartDate && customEndDate) {
        startDate = customStartDate;
        endDate = customEndDate;
      } else {
        switch (selectedRange) {
          case 'today':
            startDate = now;
            endDate = now;
            break;
          case 'yesterday':
            startDate = subDays(now, 1);
            endDate = subDays(now, 1);
            break;
          case 'last7days':
            startDate = subDays(now, 7);
            endDate = now;
            break;
          case 'thisMonth':
            startDate = startOfMonth(now);
            endDate = endOfMonth(now);
            break;
          default:
            startDate = now;
            endDate = now;
        }
      }
  
      const monthYear = format(now, 'MMM-yyyy').toLowerCase();
      if (startDate && endDate) {
        console.log(
          `Fetching data for month: ${monthYear}, date range: ${format(
            startDate,
            'd'
          )} - ${format(endDate, 'd')}`
        );
      }
  
      let totalOrders = 0;
      let totalPrice = 0;
      let statusCount = { completed: 0, cancelled: 0, pending: 0 };
      let dailyCounts = { completed: [], cancelled: [], pending: [] };
      let totalCancelledCount = 0;
      let totalCancelledPrice = 0;
  
      if (selectedRange === 'allData') {
        const monthsRef = collection(db, `adminSettings/orderAnalytics/months`);
        const monthsSnapshot = await getDocs(monthsRef);
  
        for (const monthDoc of monthsSnapshot.docs) {
          const datesRef = collection(
            db,
            `adminSettings/orderAnalytics/months/${monthDoc.id}/dates`
          );
          const datesSnapshot = await getDocs(datesRef);
  
          for (const dateDoc of datesSnapshot.docs) {
            const data = dateDoc.data();
            totalOrders += data.orders.length;
            data.orders.forEach((order) => {
              if (order.status !== 'cancelled') {
                totalPrice += order.products.reduce(
                  (acc, product) => acc + product.totalPrice,
                  0
                );
              } else {
                totalCancelledCount += 1;
                totalCancelledPrice += order.products.reduce(
                  (acc, product) => acc + product.totalPrice,
                  0
                );
              }
              statusCount[order.status] =
                (statusCount[order.status] || 0) + 1;
              dailyCounts[order.status].push(data.orders.length);
            });
          }
        }
      } else {
        for (
          let date = new Date(startDate);
          date <= new Date(endDate);
          date.setDate(date.getDate() + 1)
        ) {
          const dateString = format(date, 'd').toString();
          const analyticsDocRef = doc(
            db,
            `adminSettings/orderAnalytics/months/${format(
              date,
              'MMM-yyyy'
            ).toLowerCase()}/dates/${dateString}`
          );
          const docSnapshot = await getDoc(analyticsDocRef);
  
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            totalOrders += data.orders.length;
            data.orders.forEach((order) => {
              if (order.status !== 'cancelled') {
                totalPrice += order.products.reduce(
                  (acc, product) => acc + product.totalPrice,
                  0
                );
              } else {
                totalCancelledCount += 1;
                totalCancelledPrice += order.products.reduce(
                  (acc, product) => acc + product.totalPrice,
                  0
                );
              }
              statusCount[order.status] =
                (statusCount[order.status] || 0) + 1;
              dailyCounts[order.status].push(data.orders.length);
            });
          }
        }
      }
  
      setAnalyticsData({
        totalOrders,
        totalPrice,
        statusCount,
        totalCancelledCount,
        totalCancelledPrice,
      });
      generateChartData(statusCount);
      generateBarChartData(dailyCounts);
      generateLineChartData(dailyCounts);
    } catch (error) {
      Swal.fire('Error', 'Failed to fetch analytics data.', 'error');
      console.error('Error fetching analytics data:', error);
    }
  };

  const generateChartData = (statusCount) => {
    setChartData({
      labels: ['Completed', 'Cancelled', 'Pending'],
      datasets: [
        {
          label: 'Order Statuses',
          data: [
            statusCount.completed,
            statusCount.cancelled,
            statusCount.pending,
          ],
          backgroundColor: [
            'rgba(54, 162, 235, 0.7)',
            'rgba(255, 99, 132, 0.7)',
            'rgba(255, 206, 86, 0.7)',
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
          hoverBackgroundColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 206, 86, 1)',
          ],
        },
      ],
    });
  };

  const generateBarChartData = (dailyCounts) => {
    const maxLength = Math.max(
      dailyCounts.completed.length,
      dailyCounts.cancelled.length,
      dailyCounts.pending.length
    );
    const labels = Array.from({ length: maxLength }, (_, i) => i + 1);

    setBarChartData({
      labels: labels,
      datasets: [
        {
          label: 'Completed Orders',
          data: dailyCounts.completed,
          backgroundColor: 'rgba(54, 162, 235, 0.7)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
        {
          label: 'Cancelled Orders',
          data: dailyCounts.cancelled,
          backgroundColor: 'rgba(255, 99, 132, 0.7)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
        {
          label: 'Pending Orders',
          data: dailyCounts.pending,
          backgroundColor: 'rgba(255, 206, 86, 0.7)',
          borderColor: 'rgba(255, 206, 86, 1)',
          borderWidth: 1,
        },
      ],
    });
  };

  const generateLineChartData = (dailyCounts) => {
    const maxLength = Math.max(
      dailyCounts.completed.length,
      dailyCounts.cancelled.length,
      dailyCounts.pending.length
    );
    const labels = Array.from({ length: maxLength }, (_, i) => i + 1);

    setLineChartData({
      labels: labels,
      datasets: [
        {
          label: 'Completed Orders',
          data: dailyCounts.completed,
          backgroundColor: 'rgba(54, 162, 235, 0.7)',
          borderColor: 'rgba(54, 162, 235, 1)',
          fill: false,
          tension: 0.4,
        },
        {
          label: 'Cancelled Orders',
          data: dailyCounts.cancelled,
          backgroundColor: 'rgba(255, 99, 132, 0.7)',
          borderColor: 'rgba(255, 99, 132, 1)',
          fill: false,
          tension: 0.4,
        },
        {
          label: 'Pending Orders',
          data: dailyCounts.pending,
          backgroundColor: 'rgba(255, 206, 86, 0.7)',
          borderColor: 'rgba(255, 206, 86, 1)',
          fill: false,
          tension: 0.4,
        },
      ],
    });
  };

  return (
    <motion.div
      className="p-6 min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-4xl font-bold mb-8 text-center">Analytics Dashboard</h1>

      {/* Date Range Filters */}
      <div className="mb-6">
        <label
          htmlFor="dateRange"
          className="block text-lg font-semibold mb-4 text-gray-300"
        >
          Select Date Range:
        </label>
        <select
          id="dateRange"
          className="p-3 bg-gray-800 border border-gray-700 rounded shadow-md text-white hover:bg-gray-700 transition"
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value)}
        >
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="last7days">Last 7 Days</option>
          <option value="thisMonth">This Month</option>
          <option value="allData">All Data</option>
          <option value="customRange">Custom Range</option>
        </select>

        {selectedRange === 'customRange' && (
          <div className="custom-range-picker mt-4">
            <label className="block text-lg font-semibold mb-2 text-gray-300">
              Start Date:
            </label>
            <input
              type="date"
              className="p-3 bg-gray-800 border border-gray-700 rounded shadow-md text-white hover:bg-gray-700 transition mb-4"
              onChange={(e) => setCustomStartDate(new Date(e.target.value))}
            />

            <label className="block text-lg font-semibold mb-2 text-gray-300">
              End Date:
            </label>
            <input
              type="date"
              className="p-3 bg-gray-800 border border-gray-700 rounded shadow-md text-white hover:bg-gray-700 transition mb-4"
              onChange={(e) => setCustomEndDate(new Date(e.target.value))}
            />
          </div>
        )}
      </div>

      {analyticsData && (
        <motion.div
          className="mb-10"
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <h2 className="text-3xl font-bold mb-4">Analytics Summary</h2>
          <p className="text-xl text-yellow-400">Total Orders: {analyticsData.totalOrders}</p>
          <p className="text-xl text-green-400">Total Sales: ₹ {analyticsData.totalPrice.toFixed(2)}</p>
          <p className="text-xl text-blue-400">Total Cancelled Orders: {analyticsData.totalCancelledCount}</p>
          <p className="text-xl text-red-400">Total Cancelled Sales: ₹ {analyticsData.totalCancelledPrice.toFixed(2)}</p>
        </motion.div>
      )}

      {chartData && (
        <motion.div
          className="w-full md:w-1/2 lg:w-1/3 mx-auto mb-10"
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="text-2xl font-semibold text-center mb-6">
            Order Status Distribution
          </h3>
          <div className="bg-gray-900 p-4 rounded-lg shadow-lg mb-6">
            <Pie data={chartData} />
          </div>
        </motion.div>
      )}

      {barChartData && (
        <motion.div
          className="w-full md:w-1/2 lg:w-2/3 mx-auto mb-10"
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="text-2xl font-semibold text-center mb-6">
            Daily Order Counts
          </h3>
          <div className="bg-gray-900 p-4 rounded-lg shadow-lg mb-6">
            <Bar
              data={barChartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.dataset.label + ': ' + context.raw;
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </motion.div>
      )}

      {lineChartData && (
        <motion.div
          className="w-full md:w-1/2 lg:w-2/3 mx-auto mb-10"
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="text-2xl font-semibold text-center mb-6">
            Daily Order Trends
          </h3>
          <div className="bg-gray-900 p-4 rounded-lg shadow-lg">
            <Line
              data={lineChartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.dataset.label + ': ' + context.raw;
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default Analytics;