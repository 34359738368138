import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, useAuth } from './utils/AuthContext';

import MainPage from './pages/adminPanel/adminPage';
import Login from './components/login/login';
import DetailPage from './components/adminComps/Settings/details';
import ProtectedRoute from './utils/PrivateRoute';
import RedirectIfAuthenticated from './utils/RedirectIfAuthenticated'; // Import the redirect component

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen">
          <Routes>
            <Route
              path="/"
              element={
                <RedirectIfAuthenticated>
                  <Login />
                </RedirectIfAuthenticated>
              }
            />
            <Route
              path="/main/*"
              element={<ProtectedRoute element={<MainPage />} />}
            />
            <Route
              path="/main/settings/:section"
              element={<ProtectedRoute element={<DetailPage />} />}
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;