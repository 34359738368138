import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';

const PulseZestLogoURL = 'https://firebasestorage.googleapis.com/v0/b/pulsezest.appspot.com/o/logo.png?alt=media&token=208465a0-63ae-4999-9c75-cf976af6a616';
const CityHeartLogoURL = 'https://firebasestorage.googleapis.com/v0/b/city-heart.appspot.com/o/city-logo.mp4?alt=media&token=adc2936f-082e-4986-8a15-85ab0c282e0a';

const WelcomePage = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1;
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/main/orders');
    }, 3000); // Redirects after 3 seconds

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [navigate]);

  return (
    <div className=" min-h-screen flex flex-col items-center justify-center p-8 bg-gradient-to-br from-black to-gray-800">
      <h1 className="text-5xl font-extrabold text-green-400 mb-6">Welcome City Heart! ❤️</h1>
      
      <div className="relative w-full max-w-md mb-6">
        <LazyLoad>
          <video 
            ref={videoRef}
            className="w-full rounded-lg shadow-lg"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={CityHeartLogoURL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </LazyLoad>
        <div className="absolute inset-0 bg-gray-700 opacity-30 rounded-lg"></div>
      </div>
      
      <div className="text-center">
        <p className="text-2xl font-semibold text-yellow-400 mb-4">With Love from PulseZest ❤️</p>
        <img 
          src={PulseZestLogoURL} 
          alt="PulseZest Logo" 
          className="w-36 h-36 rounded-full shadow-lg border-4 border-blue-600"
          loading="lazy" // Lazy load image
        />
      </div>
    </div>
  );
};

export default WelcomePage;
