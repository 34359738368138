import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; 
import { getStorage } from "firebase/storage"; 
import { getAuth } from "firebase/auth"; 
 

const firebaseConfig = {
    apiKey: "AIzaSyBf2v--aJ93rnM56j89U_vXw-QyLOYhcs4",
    authDomain: "city-heart.firebaseapp.com",
    projectId: "city-heart",
    storageBucket: "city-heart.appspot.com",
    messagingSenderId: "776569055916",
    appId: "1:776569055916:web:482c7ffb2453165859cd09"
  };

const app = initializeApp(firebaseConfig);

const db = getFirestore(app); 
const storage = getStorage(app);
const auth = getAuth(app); 

export { db, storage, auth };
 