import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Image from '../../assest/images/log.png'; // Ensure correct image path
import { auth } from '../../utils/firebaseconfig'; // Ensure correct import path
import { signInWithEmailAndPassword } from 'firebase/auth';
import Swal from 'sweetalert2'; // SweetAlert2 for alerts
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // React Icons
import { useAuth } from '../../utils/AuthContext'; // Authentication context

const EmailPasswordLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const navigate = useNavigate();
  const { login } = useAuth(); // Get login function from context

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handlePasswordToggle = () => setShowPassword(!showPassword);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('User logged in successfully');
      login(); // Update authentication state

      // Redirect to /main
      navigate('/main');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: error.message,
        confirmButtonColor: '#FF5722', // Customize SweetAlert2 button color
      });
      console.error('Error logging in:', error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-sm w-full text-center">
        <div className="flex justify-center mb-6">
          <img
            src={Image}
            alt="Illustration"
            className="w-40 h-40"
          />
        </div>

        <h2 className="text-white text-2xl font-semibold mb-4">Login to Your Account</h2>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-4 bg-gray-700 rounded-lg p-4">
            <div className="relative border border-gray-600 rounded-lg">
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={handleEmailChange}
                className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg focus:outline-none"
                required
              />
            </div>
            
            <div className="relative border border-gray-600 rounded-lg">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg focus:outline-none"
                required
              />
              <button
                type="button"
                onClick={handlePasswordToggle}
                className="absolute inset-y-0 right-0 flex items-center pr-3"
              >
                {showPassword ? (
                  <FaEyeSlash className="w-6 h-6 text-gray-400" />
                ) : (
                  <FaEye className="w-6 h-6 text-gray-400" />
                )}
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-orange-500 text-white font-semibold py-2 rounded-lg hover:bg-orange-600 transition duration-300 mt-4"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default EmailPasswordLogin;
