import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { db, storage, auth } from '../../utils/firebaseconfig'; // Import Firestore, Storage, and Auth
import { doc, setDoc, collection, deleteDoc, query, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { createUserWithEmailAndPassword, deleteUser, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'; // Use Heroicons or any other icon library

const DeliveryBoyManagement = () => {
    const [deliveryBoys, setDeliveryBoys] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        password: '',
        photo: null,
        aadhar: null
    });
    const [selectedBoy, setSelectedBoy] = useState(null);
    const [loading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    // Fetch delivery boys data from Firestore
    const fetchDeliveryBoys = async () => {
        setLoading(true);
        try {
            const q = query(collection(db, 'deliveryBoy'));
            const querySnapshot = await getDocs(q);
            const boys = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setDeliveryBoys(boys);
        } catch (error) {
            console.error("Error fetching delivery boys", error);
            Swal.fire('Error', 'Error fetching delivery boys', 'error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDeliveryBoys();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handlePhotoChange = (e) => {
        setFormData(prev => ({ ...prev, photo: e.target.files[0] }));
    };

    const handleAadharChange = (e) => {
        setFormData(prev => ({ ...prev, aadhar: e.target.files[0] }));
    };

    const handleAddDeliveryBoy = async () => {
        setLoading(true);
        try {
            const { email, password, name, number, photo, aadhar } = formData;

            // Check if photo is provided
            if (!photo) {
                Swal.fire('Error', 'Photo is required.', 'error');
                setLoading(false);
                return;
            }

            // Validate password
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
            if (!passwordRegex.test(password)) {
                Swal.fire('Error', 'Password must be at least 6 characters long and include uppercase, lowercase, number, and special character.', 'error');
                setLoading(false);
                return;
            }

            // Create Auth user
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const uid = userCredential.user.uid;

            // Upload photo to storage
            const photoRef = ref(storage, `deliveryBoy/${uid}/profile.jpg`);
            await uploadBytes(photoRef, photo);
            const photoURL = await getDownloadURL(photoRef);

            // Upload Aadhar card to storage (optional)
            let aadharURL = '';
            if (aadhar) {
                const aadharRef = ref(storage, `deliveryBoy/${uid}/aadhar.jpg`);
                await uploadBytes(aadharRef, aadhar);
                aadharURL = await getDownloadURL(aadharRef);
            }

            // Save delivery boy details to Firestore
            await setDoc(doc(db, 'deliveryBoy', uid), {
                name,
                number,
                email,
                photoURL,
                aadharURL
            });

            Swal.fire('Success', 'Delivery Boy added successfully!', 'success');
            setFormData({
                name: '',
                number: '',
                email: '',
                password: '',
                photo: null,
                aadhar: null
            });
            setSelectedBoy(null);
            await fetchDeliveryBoys(); // Refresh list
        } catch (error) {
            console.error("Error adding delivery boy", error);
            Swal.fire('Error', 'Error adding delivery boy', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleViewDetails = (boy) => {
        // Populate form with selected boy details
        setSelectedBoy(boy);
        setFormData({
            name: boy.name,
            number: boy.number,
            email: boy.email,
            password: '',
            photo: null,
            aadhar: null
        });
    };

    const handleUpdateDeliveryBoy = async () => {
        setLoading(true);
        try {
            const { name, email, number, photo, aadhar } = formData;
            const { id } = selectedBoy;

            // Update photo if new photo is selected
            let photoURL = selectedBoy.photoURL;
            if (photo) {
                const photoRef = ref(storage, `deliveryBoy/${id}/profile.jpg`);
                await uploadBytes(photoRef, photo);
                photoURL = await getDownloadURL(photoRef);
            }

            // Update Aadhar card if new Aadhar card is selected
            let aadharURL = selectedBoy.aadharURL;
            if (aadhar) {
                const aadharRef = ref(storage, `deliveryBoy/${id}/aadhar.jpg`);
                await uploadBytes(aadharRef, aadhar);
                aadharURL = await getDownloadURL(aadharRef);
            }

            // Update delivery boy details in Firestore
            await setDoc(doc(db, 'deliveryBoy', id), {
                name,
                number,
                email,
                photoURL,
                aadharURL
            }, { merge: true });

            Swal.fire('Success', 'Delivery Boy updated successfully!', 'success');
            setSelectedBoy(null);
            await fetchDeliveryBoys(); // Refresh list
        } catch (error) {
            console.error("Error updating delivery boy", error);
            Swal.fire('Error', 'Error updating delivery boy', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteDeliveryBoy = async (id) => {
        const confirmation = await Swal.fire({
            title: 'Are you sure?',
            text: 'This action will delete the delivery boy permanently.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });

        if (confirmation.isConfirmed) {
            setLoading(true);
            try {
                // Delete from Firestore
                await deleteDoc(doc(db, 'deliveryBoy', id));

                // Delete photos and Aadhar card from Storage
                const photoRef = ref(storage, `deliveryBoy/${id}/profile.jpg`);
                const aadharRef = ref(storage, `deliveryBoy/${id}/aadhar.jpg`);
                await deleteObject(photoRef).catch(() => console.error("No photo found for deletion"));
                await deleteObject(aadharRef).catch(() => console.error("No aadhar found for deletion"));

                // Delete the Auth user (ensure proper authentication to delete)
                const authInstance = getAuth();
                const user = authInstance.currentUser;
                if (user && user.uid === id) {
                    await deleteUser(user).catch((error) => {
                        console.error("Error deleting Auth user:", error);
                    });
                }

                Swal.fire('Deleted!', 'Delivery Boy has been deleted.', 'success');
                await fetchDeliveryBoys(); // Refresh list
            } catch (error) {
                console.error("Error deleting delivery boy", error);
                Swal.fire('Error', 'Error deleting delivery boy', 'error');
            } finally {
                setLoading(false);
            }
        }
    };

    if (loading) return <div className="text-center py-4">Loading...</div>;

    return (
        <div className="p-4 max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Add Delivery Boy</h2>

            <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter name"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">Number</label>
                <input
                    type="text"
                    name="number"
                    value={formData.number}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter phone number"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">Email</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter email"
                />
            </div>

            <div className="mb-4 relative">
                <label className="block mb-2 text-sm font-medium text-gray-700">Password</label>
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter password"
                />
                <button
                    type="button"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className="absolute right-2 top-12 transform -translate-y-1/2"
                >
                    {passwordVisible ? <EyeOffIcon className="w-5 h-5 text-gray-600" /> : <EyeIcon className="w-5 h-5 text-gray-600" />}
                </button>
            </div>
        

            <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">Photo</label>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handlePhotoChange}
                    className="w-full border border-gray-300 rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">Aadhar Card (optional)</label>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleAadharChange}
                    className="w-full border border-gray-300 rounded-md"
                />
            </div>

            <button
                onClick={handleAddDeliveryBoy}
                className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
                Add Delivery Boy
            </button>

            <h2 className="text-2xl font-bold mt-6 mb-4 text-gray-800">Delivery Boys List</h2>
            <ul>
                {deliveryBoys.map(boy => (
                    <li key={boy.id} className="flex items-center justify-between py-2 border-b">
                        <div className="flex items-center">
                            <img
                                src={boy.photoURL || 'default_image_url'} // Fallback to default image if none exists
                                alt={boy.name}
                                className="w-12 h-12 rounded-full mr-3"
                            />
                            <div className="flex flex-col">
                                <span className="font-semibold text-gray-800">{boy.name}</span>
                                <span className="text-gray-600">{boy.number}</span>
                            </div>
                        </div>
                        <button
                            onClick={() => handleViewDetails(boy)}
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                        >
                            View
                        </button>
                    </li>
                ))}
            </ul>

            {/* Modal for editing and viewing details */}
            {selectedBoy && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/2">
                        <h3 className="text-xl font-bold mb-4">Edit Delivery Boy</h3>

                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                                placeholder="Enter name"
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">Number</label>
                            <input
                                type="text"
                                name="number"
                                value={formData.number}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                                placeholder="Enter phone number"
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">Photo</label>
                            {selectedBoy.photoURL && (
                                <img
                                    src={selectedBoy.photoURL}
                                    alt="Profile"
                                    className="w-12 h-12 rounded-full mb-2"
                                />
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handlePhotoChange}
                                className="w-full border border-gray-300 rounded-md"
                            />
                            <button
                                onClick={() => setFormData(prev => ({ ...prev, photo: null }))}
                                className="w-full py-2 mt-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                            >
                                Remove Photo
                            </button>
                        </div>

                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">Aadhar Card (optional)</label>
                            {selectedBoy.aadharURL && (
                                <img
                                    src={selectedBoy.aadharURL}
                                    alt="Aadhar"
                                    className="w-12 h-12 rounded-full mb-2"
                                />
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleAadharChange}
                                className="w-full border border-gray-300 rounded-md"
                            />
                            <button
                                onClick={() => setFormData(prev => ({ ...prev, aadhar: null }))}
                                className="w-full py-2 mt-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                            >
                                Remove Aadhar Card
                            </button>
                        </div>

                        <div className="flex justify-between">
                            <button
                                onClick={handleUpdateDeliveryBoy}
                                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                            >
                                Save Changes
                            </button>
                            <button
                                onClick={() => setSelectedBoy(null)}
                                className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => handleDeleteDeliveryBoy(selectedBoy.id)}
                                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeliveryBoyManagement;
