import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { db } from '../../../../../utils/firebaseconfig';
import { doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';

const HeroText = () => {
  const [heroText, setHeroText] = useState('');
  const [isLive, setIsLive] = useState(false);
  const [loading, setLoading] = useState(true);

  const docRef = doc(db, 'adminSettings', 'heroText');

  // Fetch data from Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setHeroText(data.heroText || '');
          setIsLive(data.isLive || false);
        }
      } catch (error) {
        Swal.fire('Error', 'Error fetching data from Firestore', 'error');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Save hero text and isLive status
  const saveData = async () => {
    setLoading(true);
    try {
      await setDoc(docRef, {
        heroText: heroText,
        isLive: isLive,
        lastUpdate: Timestamp.fromDate(new Date()),
      });
      Swal.fire('Success', 'Data saved successfully', 'success');
    } catch (error) {
      Swal.fire('Error', 'Error saving data to Firestore', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Handle text change
  const handleTextChange = (e) => {
    setHeroText(e.target.value);
  };

  // Handle live toggle
  const handleLiveToggle = async () => {
    const newIsLiveStatus = !isLive;
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to turn ${newIsLiveStatus ? 'on' : 'off'} the live status?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!',
    });

    if (confirmation.isConfirmed) {
      setIsLive(newIsLiveStatus);
      saveData();
    }
  };

  if (loading) return <div className="text-center py-4">Loading...</div>;

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Update Hero Text</h2>
      
      <textarea
        value={heroText}
        onChange={handleTextChange}
        rows="4"
        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none mb-4"
        placeholder="Enter your hero text here"
      />
      
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={saveData}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-500"
        >
          Save Text
        </button>
        
        <button
          onClick={handleLiveToggle}
          className={`px-4 py-2 rounded-lg focus:ring-2 ${
            isLive ? 'bg-red-600 text-white hover:bg-red-700' : 'bg-green-600 text-white hover:bg-green-700'
          }`}
        >
          {isLive ? 'Set Unlive' : 'Set Live'}
        </button>
      </div>
      
      <div className="text-gray-500">
        <p>Status: <span className={`font-bold ${isLive ? 'text-green-600' : 'text-red-600'}`}>
          {isLive ? 'Live' : 'Not Live'}
        </span></p>
      </div>
    </div>
  );
};

export default HeroText;
