import React, { useState, useEffect } from 'react';
import { db } from '../../../../../utils/firebaseconfig';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';

const MinValuePage = () => {
  const [minValue, setMinValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const minValueDocRef = doc(db, 'adminSettings', 'minValueDoc');

  // Fetch the current minimum value from Firestore
  useEffect(() => {
    const fetchMinValue = async () => {
      try {
        const docSnap = await getDoc(minValueDocRef);
        if (docSnap.exists()) {
          setMinValue(docSnap.data().minValue || '');
          setInputValue(docSnap.data().minValue || '');
        } else {
          // Initialize with default value if not exists
          await setDoc(minValueDocRef, { minValue: 0 });
          setMinValue(0);
          setInputValue(0);
        }
      } catch (error) {
        console.error("Error fetching min value:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch minimum value. Please try again later.',
        });
      }
    };

    fetchMinValue();
  }, []);

  // Update the minimum value in Firestore
  const handleSave = async () => {
    if (inputValue !== '') {
      try {
        const value = parseInt(inputValue, 10);
        if (isNaN(value)) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid Value',
            text: 'Please enter a valid number.',
          });
          return;
        }
        await setDoc(minValueDocRef, { minValue: value }, { merge: true });
        setMinValue(value);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Minimum value updated successfully!',
        });
      } catch (error) {
        console.error("Error updating min value:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update minimum value. Please try again later.',
        });
      }
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-semibold mb-4">Set Minimum Value</h1>
      <div className="mb-4">
        <p className="mb-2">Current Minimum Value: {minValue}</p>
        <input
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Set new minimum value"
          className="p-2 border rounded w-1/3"
        />
        <button
          onClick={handleSave}
          className="bg-blue-500 text-white p-2 rounded ml-2"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default MinValuePage;
