import React, { useState, useEffect } from 'react';
import axios from 'axios';

function OfferMsg() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await axios.get('https://admin.cityheart.pulsezest.com/api/tokens');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching tokens:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTokens();
  }, []);

  const toggleSelectAll = () => {
    if (selectedTokens.length === users.length) {
      setSelectedTokens([]);  // Deselect all
    } else {
      setSelectedTokens(users.map(user => user.id));  // Select all document IDs
    }
  };

  const handleSendMessage = async () => {
    if (!title || !body) {
      alert('Title and body are required');
      return;
    }

    if (selectedTokens.length === 0) {
      alert('No tokens selected');
      return;
    }

    setLoading(true);

    // Prepare the list of tokens to send messages to
    const tokensToSend = users
      .filter(user => selectedTokens.includes(user.id))
      .map(user => user.token);

    try {
      const response = await axios.post('https://admin.cityheart.pulsezest.com/api/send-message', {
        tokens: tokensToSend, // Pass the selected tokens array
        title: title,
        body: body,
      });
      console.log('Messages sent:', response.data);
      alert('Messages sent successfully');
    } catch (error) {
      console.error('Error sending messages:', error);
      alert('Error sending messages');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-md mx-auto bg-white shadow-lg rounded-md">
      <h1 className="text-2xl font-bold mb-4 text-center">Send FCM Message</h1>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Enter title"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Body</label>
        <textarea
          value={body}
          onChange={(e) => setBody(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Enter body"
        ></textarea>
      </div>

      <div className="mb-4">
        <button
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${selectedTokens.length === users.length ? 'bg-red-500' : ''}`}
          onClick={toggleSelectAll}
        >
          {selectedTokens.length === users.length ? 'Deselect All Tokens' : 'Select All Tokens'}
        </button>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Selected Tokens</label>
        <ul className="list-disc list-inside">
          {users.map((user) => (
            <li key={user.id} className="flex items-center">
              <input
                type="checkbox"
                checked={selectedTokens.includes(user.id)}
                onChange={() => {
                  if (selectedTokens.includes(user.id)) {
                    setSelectedTokens(selectedTokens.filter(id => id !== user.id));
                  } else {
                    setSelectedTokens([...selectedTokens, user.id]);
                  }
                }}
                className="mr-2"
              />
              <span className="mr-2">{user.number}</span> {/* Display the Number field */}
        
            </li>
          ))}
        </ul>
      </div>

      <button
        className={`w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleSendMessage}
        disabled={loading}
      >
        {loading ? 'Sending...' : 'Send Message'}
      </button>

      {loading && <p>Loading tokens...</p>}
    </div>
  );
}

export default OfferMsg;
