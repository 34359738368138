import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { collection, doc, setDoc, serverTimestamp, getDocs, deleteDoc,Timestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../../../../utils/firebaseconfig';

const Thumb = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectionType, setSelectionType] = useState(''); // 'category' or 'product'
  const [file, setFile] = useState(null);

  // Fetch categories or products based on selectionType
  const fetchCategoriesOrProducts = async (type) => {
    try {
      if (type === 'category') {
        const categorySnapshot = await getDocs(collection(db, 'categories'));
        setCategories(categorySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));
      } else if (type === 'product') {
        const productSnapshot = await getDocs(collection(db, 'products'));
        setProducts(productSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().productName })));
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  
  // Fetch thumbnails
  const fetchThumbnails = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'thumbnails'));
      setThumbnails(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error('Error fetching thumbnails:', error.message);
    }
  };

  // Upload image
  const uploadImage = async (file, tid) => {
    const storageRef = ref(storage, `thumbnails/${tid}/image`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setIsLoading(true);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          console.error('Upload error:', error.message);
          setIsLoading(false);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setIsLoading(false);
            resolve(downloadURL);
          } catch (urlError) {
            console.error('Error getting download URL:', urlError.message);
            setIsLoading(false);
            reject(urlError);
          }
        }
      );
    });
  };

  // Handle file change
  const handleFileChange = (e) => setFile(e.target.files[0]);

  // Handle create upload
  const handleCreateUpload = async () => {
    if (!file) {
      Swal.fire('Error', 'Please select a file to upload', 'error');
      return;
    }
  
    const tid = await generateUniqueTID();
    try {
      const imageUrl = await uploadImage(file, tid);
      let itemUrl = '';
  
      if (selectionType === 'product' && selectedItems.length > 0) {
        itemUrl = `https://city-heart.pulsezest.com/product/${selectedItems[0].id}`;
      } else if (selectionType === 'category' && selectedItems.length > 0) {
        itemUrl = `https://city-heart.pulsezest.com/categories/${selectedItems[0].id}`;
      }
  
      await setDoc(doc(db, 'thumbnails', tid), {
        tid,
        imageUrl,
        itemUrl,
        selectionType,
        timestamp: Timestamp.fromDate(new Date()),
      });
  
      Swal.fire('Uploaded!', 'Your thumbnail has been uploaded.', 'success');
      fetchThumbnails();
      closeCreateModal();
    } catch (error) {
      Swal.fire('Error!', 'There was a problem uploading the thumbnail.', 'error');
      console.error('Error uploading thumbnail:', error.message);
    }
  };

  // Delete thumbnail
  const deleteThumbnail = async (id, tid) => {
    const storageRef = ref(storage, `thumbnails/${tid}/image`);
    try {
      await deleteObject(storageRef);
      await deleteDoc(doc(db, 'thumbnails', id));
      Swal.fire('Deleted!', 'Your thumbnail has been deleted.', 'success');
      fetchThumbnails();
    } catch (error) {
      console.error('Error deleting thumbnail:', error.message);
      Swal.fire('Error!', 'There was a problem deleting the thumbnail.', 'error');
    }
  };

  // Generate unique TID
  const generateUniqueTID = async () => {
    const querySnapshot = await getDocs(collection(db, 'thumbnails'));
    const existingTIDs = querySnapshot.docs.map(doc => doc.data().tid);
    const maxTID = existingTIDs.reduce((max, tid) => Math.max(max, parseInt(tid.split('-')[1], 10)), 0);
    return `tid-${String(maxTID + 1).padStart(2, '0')}`;
  };

  // Handle selection type change
  const handleSelectionTypeChange = (type) => {
    setSelectionType(type);
    setSelectedItems([]);
    setSearchTerm('');
  };

  // Handle item selection
  const handleItemSelect = (item) => {
    setSelectedItems(prevSelected =>
      prevSelected.find(selected => selected.id === item.id)
        ? prevSelected.filter(selected => selected.id !== item.id)
        : [item]
    );
  };

  // Filter items based on search term
  const filteredItems = (selectionType === 'category' ? categories : products).filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Reset form
  const resetForm = () => {
    setFile(null);
    setSelectedItems([]);
    setSearchTerm('');
    setSelectionType(''); // Clear selection type as well
  };

  const openCreateModal = () => {
    setIsCreateModalOpen(true);
    resetForm();
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    resetForm();
  };

  useEffect(() => {
    fetchThumbnails();
  }, []);

  useEffect(() => {
    if (selectionType) {
      fetchCategoriesOrProducts(selectionType);
    }
  }, [selectionType]);

  return (
    <div>
      <button
        className="bg-gradient-to-r from-blue-500 to-teal-500 text-white py-2 px-4 rounded mb-4 hover:opacity-90 transition"
        onClick={openCreateModal}
      >
        Upload Thumbnail
      </button>
  
      {isCreateModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="relative bg-white rounded-lg p-6 w-full max-w-md shadow-lg">
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                }}
              >
                <div
                  style={{
                    border: '16px solid #f3f3f3', /* Light grey */
                    borderTop: '16px solid #3498db', /* Blue */
                    borderRadius: '50%',
                    width: '120px',
                    height: '120px',
                    animation: 'spin 2s linear infinite',
                  }}
                />
              </div>
            )}
            <h2 className="text-xl font-semibold mb-4">Upload Thumbnail</h2>
            <input type="file" onChange={handleFileChange} className="mb-4 w-full border border-gray-300 rounded p-2" />
            <div className="flex space-x-4 mb-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="selectionType"
                  value="category"
                  checked={selectionType === 'category'}
                  onChange={() => handleSelectionTypeChange('category')}
                />
                <span className="ml-2">Category</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="selectionType"
                  value="product"
                  checked={selectionType === 'product'}
                  onChange={() => handleSelectionTypeChange('product')}
                />
                <span className="ml-2">Product</span>
              </label>
            </div>
            <input
              type="text"
              placeholder={`Search ${selectionType === 'category' ? 'Categories' : 'Products'}...`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-2 border rounded mb-4 w-full"
            />
            <div className="mb-4 max-h-48 overflow-y-auto border border-gray-300 rounded p-2">
              {(selectionType === 'category' ? categories : products).length === 0 ? (
                <p className="text-gray-500">No results found.</p>
              ) : (
                (selectionType === 'category' ? categories : products).filter(item =>
                  item.name.toLowerCase().includes(searchTerm.toLowerCase())
                ).map(item => (
                  <div key={item.id} className="flex items-center mb-2">
                    <input
                      type="radio"
                      checked={selectedItems.some(selected => selected.id === item.id)}
                      onChange={() => handleItemSelect(item)}
                    />
                    <span className="ml-2">{item.name}</span>
                  </div>
                ))
              )}
            </div>
            <div className="mb-4">
              <h4 className="font-semibold">Selected {selectionType === 'category' ? 'Categories' : 'Products'}:</h4>
              <ul className="list-disc pl-5">
                {selectedItems.map(item => (
                  <li key={item.id} className="text-gray-700">{item.name}</li>
                ))}
              </ul>
            </div>
            <button
              className="bg-green-500 text-white py-2 px-4 rounded w-full mb-2 hover:opacity-90 transition"
              onClick={handleCreateUpload}
            >
              Upload
            </button>
            <button
              className="bg-red-500 text-white py-2 px-4 rounded w-full hover:opacity-90 transition"
              onClick={closeCreateModal}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
  
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-6">
        {thumbnails.map(({ id, imageUrl, tid, itemUrl }) => (
          <div key={id} className="relative border border-gray-300 p-2 rounded shadow-lg flex items-center">
            <img src={imageUrl} alt="Thumbnail" className="w-20 h-20 object-cover rounded mr-2" />
            {itemUrl && (
              <a
                href={itemUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="absolute top-0 left-0 mt-2 ml-2 bg-blue-500 text-white py-1 px-2 rounded hover:opacity-80 transition"
              >
                View Item
              </a>
            )}
            <button
              className="absolute top-0 right-0 mt-2 mr-2 bg-red-500 text-white py-1 px-2 rounded hover:opacity-80 transition"
              onClick={() => deleteThumbnail(id, tid)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Thumb;
