import React, { useState, useEffect } from 'react';
import { FaPlus, FaEdit, FaTrash, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import SweetAlert from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  collection,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  Timestamp,
  getDocs,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../../utils/firebaseconfig';
import Loader from '../../../pages/loader';
import slugify from 'slugify';

const MySwal = withReactContent(SweetAlert);

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [operationLoading, setOperationLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedCategory, setExpandedCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(collection(db, 'categories'));
    const fetchedCategories = [];
    querySnapshot.forEach((doc) => {
      fetchedCategories.push({ id: doc.id, ...doc.data() });
    });
    setCategories(fetchedCategories);
    setLoading(false);
  };

  const generateCID = async () => {
    const querySnapshot = await getDocs(collection(db, 'categories'));
    const cids = querySnapshot.docs.map(doc => doc.data().cid);
    let maxCid = 0;
    cids.forEach(cid => {
      const cidNumber = parseInt(cid.split('-')[1], 10);
      if (cidNumber > maxCid) maxCid = cidNumber;
    });
    const newCid = `cid-${(maxCid + 1).toString().padStart(2, '0')}`;
    return newCid;
  };

  const generateSCID = async (parentCid) => {
    const querySnapshot = await getDocs(collection(db, 'categories', parentCid, 'subCategories'));
    const scids = querySnapshot.docs.map(doc => doc.data().scid);
    let maxScid = 0;
    scids.forEach(scid => {
      const scidNumber = parseInt(scid.split('-')[1], 10);
      if (scidNumber > maxScid) maxScid = scidNumber;
    });
    const newScid = `scid-${(maxScid + 1).toString().padStart(2, '0')}`;
    return newScid;
  };

  const createSlug = (name) => {
    return slugify(name, { lower: true });
  };

  const handleCreateCategory = async () => {
    const { value: formValues } = await MySwal.fire({
      title: 'Create New Category',
      html:
        `<div class="relative p-6 bg-white rounded-lg shadow-lg">
          <button class="absolute top-2 right-2 text-xl text-red-500 hover:text-red-700" id="swal-close-btn">&times;</button>
          <input id="swal-input1" class="swal2-input mb-3 w-full p-2 border rounded" placeholder="Category Name">
          <input id="swal-input2" type="file" class="swal2-file mb-3">
          <div class="mb-3">
            <label class="inline-flex items-center">
              <input type="checkbox" id="swal-input3" class="mr-2"> Create Subcategory
            </label>
          </div>
          <input id="swal-input4" class="swal2-input w-full p-2 border rounded mb-3" placeholder="Subcategory Name" style="display:none;">
          <input id="swal-input5" type="file" class="swal2-file mb-3" style="display:none;">
        </div>`,
      didOpen: () => {
        document.getElementById('swal-close-btn').addEventListener('click', () => {
          MySwal.close();
        });
        document.getElementById('swal-input3').addEventListener('change', (e) => {
          document.getElementById('swal-input4').style.display = e.target.checked ? 'block' : 'none';
          document.getElementById('swal-input5').style.display = e.target.checked ? 'block' : 'none';
        });
      },
      preConfirm: async () => {
        const name = document.getElementById('swal-input1').value;
        const imageFile = document.getElementById('swal-input2').files[0];
        const createSubcategory = document.getElementById('swal-input3').checked;
        const subcategoryName = createSubcategory ? document.getElementById('swal-input4').value : null;
        const subcategoryImageFile = createSubcategory ? document.getElementById('swal-input5').files[0] : null;

        if (!name || !imageFile) {
          MySwal.showValidationMessage('Please enter a category name and upload an image.');
        }

        return { name, imageFile, createSubcategory, subcategoryName, subcategoryImageFile };
      },
    });

    if (formValues) {
      const { name, imageFile, createSubcategory, subcategoryName, subcategoryImageFile } = formValues;
      setOperationLoading(true);

      try {
        const cid = await generateCID();
        const slug = createSlug(name);
        const imagePath = `categories/${cid}/${cid}`;
        const storageRef = ref(storage, imagePath);

        await uploadBytes(storageRef, imageFile);
        const imageUrl = await getDownloadURL(storageRef);
        const newCategory = { name, cid, image: imageUrl, slug, timestamp: Date.now() };

        await setDoc(doc(db, 'categories', newCategory.cid), newCategory);

        if (createSubcategory) {
          const scid = await generateSCID(cid);
          const subcategorySlug = createSlug(subcategoryName);
          const subcategoryImagePath = `subcategories/${scid}/${scid}`;
          const subcategoryStorageRef = ref(storage, subcategoryImagePath);

          await uploadBytes(subcategoryStorageRef, subcategoryImageFile);
          const subcategoryImageUrl = await getDownloadURL(subcategoryStorageRef);
          const newSubcategory = {
            name: subcategoryName,
            scid,
            slug: subcategorySlug,
            image: subcategoryImageUrl,
            timestamp: Date.now(),
            parentCid: cid,
          };
          await setDoc(doc(db, 'categories', cid, 'subCategories', scid), newSubcategory);
        }

        setCategories([...categories, { id: newCategory.cid, ...newCategory }]);
        MySwal.fire('Success!', 'Category created successfully.', 'success');
      } catch (error) {
        console.error('Error creating category: ', error);
        MySwal.fire('Error!', 'An error occurred while creating the category.', 'error');
      } finally {
        setOperationLoading(false);
      }
    }
  };

  const handleEditCategory = async (category) => {
    const { value: formValues } = await MySwal.fire({
      title: 'Edit Category',
      html:
        `<div class="relative p-6 bg-white rounded-lg shadow-lg">
          <button class="absolute top-2 right-2 text-xl text-red-500 hover:text-red-700" id="swal-close-btn">&times;</button>
          <input id="swal-input1" class="swal2-input mb-3 w-full p-2 border rounded" value="${category.name}" placeholder="Category Name">
          <input id="swal-input2" type="file" class="swal2-file mb-3">
        </div>`,
      didOpen: () => {
        document.getElementById('swal-close-btn').addEventListener('click', () => {
          MySwal.close();
        });
      },
      preConfirm: async () => {
        const name = document.getElementById('swal-input1').value;
        const imageFile = document.getElementById('swal-input2').files[0];
        if (!name) {
          MySwal.showValidationMessage('Please enter a category name.');
        }
        return { name, imageFile };
      }
    });

    if (formValues) {
      const { name, imageFile } = formValues;
      let updatedCategory = { name, slug: createSlug(name) };

      setOperationLoading(true);
      try {
        if (imageFile) {
          const imagePath = `categories/${category.cid}/${category.cid}`;
          const storageRef = ref(storage, imagePath);
          await uploadBytes(storageRef, imageFile);
          const imageUrl = await getDownloadURL(storageRef);
          updatedCategory.image = imageUrl;
        }

        const categoryRef = doc(db, 'categories', category.id);
        await updateDoc(categoryRef, updatedCategory);
        setCategories(categories.map(cat => (cat.id === category.id ? { ...cat, ...updatedCategory } : cat)));
        MySwal.fire('Success!', 'Category updated successfully.', 'success');
      } catch (error) {
        console.error('Error updating category: ', error);
        MySwal.fire('Error!', 'An error occurred while updating the category.', 'error');
      } finally {
        setOperationLoading(false);
      }
    }
  };

  const handleCreateSubcategory = async (parentCategory) => {
    const { value: formValues } = await MySwal.fire({
      title: 'Create New Subcategory',
      html:
        `<div class="relative p-6 bg-white rounded-lg shadow-lg">
          <button class="absolute top-2 right-2 text-xl text-red-500 hover:text-red-700" id="swal-close-btn">&times;</button>
          <input id="swal-input1" class="swal2-input mb-3 w-full p-2 border rounded" placeholder="Subcategory Name">
          <input id="swal-input2" type="file" class="swal2-file mb-3">
        </div>`,
      didOpen: () => {
        document.getElementById('swal-close-btn').addEventListener('click', () => {
          MySwal.close();
        });
      },
      preConfirm: async () => {
        const name = document.getElementById('swal-input1').value;
        const imageFile = document.getElementById('swal-input2').files[0];
        if (!name || !imageFile) {
          MySwal.showValidationMessage('Please enter a subcategory name and upload an image.');
        }
        return { parentCid: parentCategory.cid, name, imageFile };
      }
    });

    if (formValues) {
      const { parentCid, name, imageFile } = formValues;
      setOperationLoading(true);

      try {
        const scid = await generateSCID(parentCid);
        const slug = createSlug(name);
        const imagePath = `subcategories/${parentCid}/${scid}`;
        const storageRef = ref(storage, imagePath);

        await uploadBytes(storageRef, imageFile);
        const imageUrl = await getDownloadURL(storageRef);
        const newSubcategory = {
          name,
          scid,
          slug,
          image: imageUrl,
          timestamp: Timestamp.fromDate(new Date()),
          parentCid,
        };
        await setDoc(doc(db, 'categories', parentCid, 'subCategories', scid), newSubcategory);
        setCategories(categories.map(cat => {
          if (cat.id === parentCid) {
            return {
              ...cat,
              subCategories: [...(cat.subCategories || []), newSubcategory]
            };
          }
          return cat;
        }));
        MySwal.fire('Success!', 'Subcategory created successfully.', 'success');
      } catch (error) {
        console.error('Error creating subcategory: ', error);
        MySwal.fire('Error!', 'An error occurred while creating the subcategory.', 'error');
      } finally {
        setOperationLoading(false);
      }
    }
  };

  const handleEditSubcategory = async (parentCategory, subcategory) => {
    const { value: formValues } = await MySwal.fire({
      title: 'Edit Subcategory',
      html:
        `<div class="relative p-6 bg-white rounded-lg shadow-lg">
          <button class="absolute top-2 right-2 text-xl text-red-500 hover:text-red-700" id="swal-close-btn">&times;</button>
          <input id="swal-input1" class="swal2-input mb-3 w-full p-2 border rounded" value="${subcategory.name}" placeholder="Subcategory Name">
          <input id="swal-input2" type="file" class="swal2-file mb-3">
        </div>`,
      didOpen: () => {
        document.getElementById('swal-close-btn').addEventListener('click', () => {
          MySwal.close();
        });
      },
      preConfirm: async () => {
        const name = document.getElementById('swal-input1').value;
        const imageFile = document.getElementById('swal-input2').files[0];
        return { name, imageFile };
      }
    });

    if (formValues) {
      const { name, imageFile } = formValues;
      let updatedSubcategory = { name, slug: createSlug(name) };

      setOperationLoading(true);
      try {
        if (imageFile) {
          const imagePath = `subcategories/${parentCategory.cid}/${subcategory.scid}`;
          const storageRef = ref(storage, imagePath);
          await uploadBytes(storageRef, imageFile);
          const imageUrl = await getDownloadURL(storageRef);
          updatedSubcategory.image = imageUrl;
        }

        const subcategoryRef = doc(db, 'categories', parentCategory.id, 'subCategories', subcategory.scid);
        await updateDoc(subcategoryRef, updatedSubcategory);
        setCategories(categories.map(cat => {
          if (cat.id === parentCategory.id) {
            return {
              ...cat,
              subCategories: cat.subCategories.map(sub => (sub.scid === subcategory.scid ? { ...sub, ...updatedSubcategory } : sub))
            };
          }
          return cat;
        }));
        MySwal.fire('Success!', 'Subcategory updated successfully.', 'success');
      } catch (error) {
        console.error('Error updating subcategory: ', error);
        MySwal.fire('Error!', 'An error occurred while updating the subcategory.', 'error');
      } finally {
        setOperationLoading(false);
      }
    }
  };

  const handleDeleteSubcategory = async (parentCategory, subcategory) => {
    const result = await MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      setOperationLoading(true);
      try {
        await deleteDoc(doc(db, 'categories', parentCategory.id, 'subCategories', subcategory.scid));
        const imageRef = ref(storage, `subcategories/${parentCategory.cid}/${subcategory.scid}`);
        await deleteObject(imageRef);
        setCategories(categories.map(cat => {
          if (cat.id === parentCategory.id) {
            return {
              ...cat,
              subCategories: cat.subCategories.filter(sub => sub.scid !== subcategory.scid)
            };
          }
          return cat;
        }));
        MySwal.fire('Deleted!', 'Your subcategory has been deleted.', 'success');
      } catch (error) {
        console.error('Error deleting subcategory: ', error);
        MySwal.fire('Error!', 'An error occurred while deleting the subcategory.', 'error');
      } finally {
        setOperationLoading(false);
      }
    }
  };

  const handleDeleteCategory = async (category) => {
    const result = await MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      setOperationLoading(true);
      try {
        const subcategoriesSnapshot = await getDocs(collection(db, 'categories', category.id, 'subCategories'));
        for (const subcategoryDoc of subcategoriesSnapshot.docs) {
          const subcategory = subcategoryDoc.data();
          const subcategoryImageRef = ref(storage, `subcategories/${category.cid}/${subcategory.scid}`);
          await deleteObject(subcategoryImageRef);
        }
        await deleteDoc(doc(db, 'categories', category.id));
        const imageRef = ref(storage, `categories/${category.cid}/${category.cid}`);
        await deleteObject(imageRef);
        setCategories(categories.filter(cat => cat.id !== category.id));
        MySwal.fire('Deleted!', 'Your category has been deleted.', 'success');
      } catch (error) {
        console.error('Error deleting category: ', error);
        MySwal.fire('Error!', 'An error occurred while deleting the category.', 'error');
      } finally {
        setOperationLoading(false);
      }
    }
  };

  const handleToggleExpand = (category) => {
    setExpandedCategory(expandedCategory === category.id ? null : category.id);
  };

  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-4 bg-gradient-to-r from-blue-500 to-purple-500 pb-[calc(60px+1rem)]">
    {/* Sticky header for search and create button */}
    <div className="sticky top-0 bg-white z-10 p-4 mb-4 shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">Categories</h1>
        <button
          onClick={handleCreateCategory}
          className="bg-blue-500 text-white p-2 rounded shadow hover:bg-blue-600 flex items-center justify-center"
        >
          <FaPlus className="mr-2" /> Create
        </button>
      </div>
      <div className="flex justify-between items-center">
        <input
          type="text"
          placeholder="Search Categories..."
          className="p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    </div>

  
  
    {/* Category list */}
    {loading || operationLoading ? (
      <Loader />
    ) : (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredCategories.map(category => (
          <div key={category.id} className="bg-white p-4 rounded shadow transition-all hover:shadow-lg flex flex-col">
            <div className="flex items-start mb-4">
              <img src={category.image} alt={category.name} className="w-16 h-16 object-cover rounded mr-4" />
              <div className="flex-grow">
                <h3 className="text-lg font-semibold">{category.name}</h3>
                <div className="flex space-x-2 mt-2">
                  <button 
                    onClick={() => handleEditCategory(category)} 
                    className="bg-yellow-500 text-white p-2 rounded hover:bg-yellow-600 flex items-center justify-center"
                  >
                    <FaEdit />
                  </button>
                  <button 
                    onClick={() => handleDeleteCategory(category)} 
                    className="bg-red-500 text-white p-2 rounded hover:bg-red-600 flex items-center justify-center"
                  >
                    <FaTrash />
                  </button>
                  <button 
                    onClick={() => handleCreateSubcategory(category)} 
                    className="bg-green-500 text-white p-2 rounded hover:bg-green-600 flex items-center justify-center"
                  >
                    <FaPlus />
                  </button>
                  <button 
                    onClick={() => handleToggleExpand(category)} 
                    className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600 flex items-center justify-center"
                  >
                    {expandedCategory === category.id ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                </div>
              </div>
            </div>
  
            {/* Subcategories */}
            {expandedCategory === category.id && category.subCategories && (
              <div className="border-t mt-4 pt-4">
                {category.subCategories.map(subCategory => (
                  <div key={subCategory.scid} className="bg-gray-100 p-4 rounded mb-4 flex items-start">
                    <img src={subCategory.image} alt={subCategory.name} className="w-12 h-12 object-cover rounded mr-4" />
                    <div className="flex-grow">
                      <h4 className="text-md font-semibold">{subCategory.name}</h4>
                      <div className="flex space-x-2 mt-2">
                        <button 
                          onClick={() => handleEditSubcategory(category, subCategory)} 
                          className="bg-yellow-500 text-white p-2 rounded hover:bg-yellow-600 flex items-center justify-center"
                        >
                          <FaEdit />
                        </button>
                        <button 
                          onClick={() => handleDeleteSubcategory(category, subCategory)} 
                          className="bg-red-500 text-white p-2 rounded hover:bg-red-600 flex items-center justify-center"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )}
  </div>
  
  );
};

export default Category;


