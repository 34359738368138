import React, { useState, useEffect } from 'react';
import { FaPlus, FaEdit, FaTrash, FaTimesCircle } from 'react-icons/fa';
import SweetAlert from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  collection,
  setDoc,
  doc,
  getDocs,
  deleteDoc,
  Timestamp,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../../utils/firebaseconfig';
import Loader from '../../../pages/loader';
import slugify from 'slugify';

const MySwal = withReactContent(SweetAlert);

const Product = () => {
  const [categories, setCategories] = useState([]);
  const [superCategories, setSuperCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [operationLoading, setOperationLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState('');
  const [editProduct, setEditProduct] = useState(null);
  const [isVariantEnabled, setIsVariantEnabled] = useState(true); // Toggle state for variant
  const [formValues, setFormValues] = useState({
    productName: '',
    cid: '',
    scid: '',
    featureImageFile: null,
    multipleImages: [],
    previousMultipleImages: [],
    description: '',
    variants: [{ quantity: '', unit: '', price: '' }],
    price: '',
    salePrice: '',
    stock: '',
  });

  useEffect(() => {
    fetchCategories();
    fetchSuperCategories();
    fetchProducts();
  }, []);

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, 'categories'));
    const fetchedCategories = [];
    querySnapshot.forEach((doc) => {
      fetchedCategories.push({ id: doc.id, ...doc.data() });
    });
    setCategories(fetchedCategories);
  };

  // Fetching Super Categories
  const fetchSuperCategories = async () => {
    const querySnapshot = await getDocs(collection(db, 'superCategories'));
    const fetchedSuperCategories = [];
    querySnapshot.forEach((doc) => {
      fetchedSuperCategories.push({ id: doc.id, ...doc.data() });
    });
    setSuperCategories(fetchedSuperCategories);
  };

  const generatePID = async () => {
    const querySnapshot = await getDocs(collection(db, 'products'));
    const pids = querySnapshot.docs.map((doc) => doc.data().pid);
    let maxPid = 0;
    pids.forEach((pid) => {
      const pidNumber = parseInt(pid.split('-')[1], 10);
      if (pidNumber > maxPid) maxPid = pidNumber;
    });
    const newPid = `pid-${(maxPid + 1).toString().padStart(2, '0')}`;
    return newPid;
  };

  const fetchProducts = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(collection(db, 'products'));
    const fetchedProducts = [];
    querySnapshot.forEach((doc) => {
      fetchedProducts.push({ id: doc.id, ...doc.data() });
    });
    setProducts(fetchedProducts);
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormValues({
      ...formValues,
      [name]: files.length ? (name === 'multipleImages' ? files : files[0]) : null,
    });
  };

  const handleVariantChange = (index, e) => {
    const { name, value } = e.target;
    const updatedVariants = formValues.variants.map((variant, i) =>
      i === index ? { ...variant, [name]: value } : variant
    );
    setFormValues({
      ...formValues,
      variants: updatedVariants,
    });
  };

  const addVariant = () => {
    setFormValues({
      ...formValues,
      variants: [...formValues.variants, { quantity: '', unit: '', price: '' }],
    });
  };

  const removeVariant = (index) => {
    setFormValues({
      ...formValues,
      variants: formValues.variants.filter((_, i) => i !== index),
    });
  };

  const handleRemovePreviousImage = (index) => {
    setFormValues({
      ...formValues,
      previousMultipleImages: formValues.previousMultipleImages.filter((_, i) => i !== index),
    });
  };

  const handleRemoveFeatureImage = () => {
    setFormValues({
      ...formValues,
      featureImageFile: null,
      featureImageUrl: null,
    });
  };

  const handleShowCreateModal = () => {
    setEditProduct(null);
    setFormValues({
      productName: '',
      cid: '',
      scid: '',
      featureImageFile: null,
      multipleImages: [],
      previousMultipleImages: [],
      description: '',
      variants: [{ quantity: '', unit: '', price: '' }],
      price: '',
      salePrice: '',
      stock: '',
    });
    setShowModal(true);
    setIsVariantEnabled(true); // Reset the variant toggle state when opening the modal
  };

  const handleCreateOrUpdateProduct = async () => {
    const {
      productName,
      cid,
      scid, // Handle Super Category
      featureImageFile,
      multipleImages,
      description,
      variants,
      price,
      salePrice,
      stock,
      previousMultipleImages,
    } = formValues;

    // Ensuring Category and Variant Price are Mandatory
    if (!productName || !cid || (!isVariantEnabled && (!price || !stock)) || (isVariantEnabled && (!variants[0].quantity || !variants[0].unit || !variants[0].price))) {
      return MySwal.fire('Error', 'Please complete all required fields.', 'error');
    }

    if ((!isVariantEnabled && (isNaN(price) || isNaN(stock))) || (isVariantEnabled && isNaN(variants[0].price))) {
      return MySwal.fire('Error', 'Price, Stock, and Variant Price must be valid numbers.', 'error');
    }

    setOperationLoading(true);
    setIsCreating(true);

    try {
      const pid = editProduct ? editProduct.pid : await generatePID();
      const slug = slugify(productName, { lower: true });
      let featureImageUrl = editProduct?.featureImage;

      if (featureImageFile) {
        const featureImagePath = `products/${pid}/feature`;
        const storageRef = ref(storage, featureImagePath);
        await uploadBytes(storageRef, featureImageFile);
        featureImageUrl = await getDownloadURL(storageRef);
      }

      const multipleImageUrls = [...previousMultipleImages];
      for (let i = 0; i < multipleImages.length; i++) {
        const image = multipleImages[i];
        const imagePath = `products/${pid}/images/${image.name}`;
        const imageRef = ref(storage, imagePath);

        await uploadBytes(imageRef, image);
        const imageUrl = await getDownloadURL(imageRef);

        multipleImageUrls.push(imageUrl);
      }

      const newProduct = {
        productName,
        cid,
        scid: scid || '',
        featureImage: featureImageUrl,
        multipleImage: multipleImageUrls,
        description,
        variants: isVariantEnabled ? variants : [],
        price: parseFloat(price),
        salePrice: parseFloat(salePrice) || 0,
        stock: parseInt(stock, 10),
        pid,
        slug,
        review: editProduct?.review || [],
        timestamp: Timestamp.fromDate(new Date()),
      };

      await setDoc(doc(db, 'products', pid), newProduct);

      if (editProduct) {
        setProducts((prevProducts) =>
          prevProducts.map((p) => (p.pid === pid ? { id: newProduct.pid, ...newProduct } : p))
        );
        setEditProduct(null);
      } else {
        setProducts([...products, { id: newProduct.pid, ...newProduct }]);
      }

      MySwal.fire('Success!', `Product ${editProduct ? 'updated' : 'created'} successfully.`, 'success');
    } catch (error) {
      console.error('Error creating product: ', error);
      MySwal.fire('Error', `An error occurred while ${editProduct ? 'updating' : 'creating'} the product.`, 'error');
    } finally {
      setOperationLoading(false);
      setShowModal(false);
      setFormValues({
        productName: '',
        cid: '',
        scid: '',
        featureImageFile: null,
        multipleImages: [],
        previousMultipleImages: [],
        description: '',
        variants: [{ quantity: '', unit: '', price: '' }],
        price: '',
        salePrice: '',
        stock: '',
      });
      setIsCreating(false);
    }
  };

  const handleDeleteProduct = async (product) => {
    const confirmation = await MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    });

    if (confirmation.isConfirmed) {
      setOperationLoading(true);
      try {
        try {
          const featureImageRef = ref(storage, `products/${product.pid}/feature`);
          await deleteObject(featureImageRef);
        } catch (e) {
          console.warn('Feature image not found, continuing deletion...');
        }

        for (let imageUrl of product.multipleImage) {
          try {
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
          } catch (e) {
            console.warn(`${imageUrl} not found, continuing deletion...`);
          }
        }

        await deleteDoc(doc(db, 'products', product.pid));

        setProducts((prevProducts) => prevProducts.filter((p) => p.pid !== product.pid));

        MySwal.fire('Deleted!', 'Your product has been deleted.', 'success');
      } catch (error) {
        console.error('Error deleting product: ', error);
        MySwal.fire('Error', 'An error occurred while deleting the product.', 'error');
      } finally {
        setOperationLoading(false);
      }
    }
  };

  const handleVariantToggle = () => {
    setIsVariantEnabled(!isVariantEnabled);
    if (!isVariantEnabled) {
      setFormValues({
        ...formValues,
        price: '',
        salePrice: '',
      });
    }
  };

  return (
    <div className="relative p-4 bg-gradient-to-r from-blue-800  to-pink-400 pb-[calc(60px+1rem)]">
      {/* Header */}
      <div className="sticky top-0 bg-white z-20 p-4 mb-4 shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-semibold">Products</h1>
          <div className="flex space-x-4">
            <button
              onClick={handleShowCreateModal}
              className="bg-blue-500 text-white p-2 rounded shadow hover:bg-blue-600 flex items-center justify-center"
            >
              <FaPlus className="mr-2" /> Create
            </button>
          </div>
        </div>
        <input
          type="text"
          placeholder="Search Products..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="p-2 border rounded w-full"
        />
      </div>

      {/* Main Content */}
      {loading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {products
            .filter((product) =>
              product.productName.toLowerCase().includes(search.toLowerCase())
            )
            .map((product) => (
              <div key={product.pid} className="bg-white p-4 rounded shadow transition-all hover:shadow-lg flex flex-col">
                <div className="flex items-start mb-4">
                  <img
                    src={product.featureImage}
                    alt={product.productName}
                    className="w-16 h-16 object-cover rounded mr-4"
                  />
                  <div className="flex-grow">
                    <h3 className="text-lg font-semibold">{product.productName}</h3>
                    <div className="flex space-x-2 mt-2 justify-end">
                      <button
                        onClick={() => {
                          setEditProduct(product);
                          setFormValues({
                            productName: product.productName,
                            cid: product.cid,
                            scid: product.scid,
                            featureImageFile: null,
                            multipleImages: [],
                            previousMultipleImages: product.multipleImage,
                            description: product.description,
                            variants: product.variants || [{ quantity: '', unit: '', price: '' }],
                            price: product.price,
                            salePrice: product.salePrice,
                            stock: product.stock,
                            featureImageUrl: product.featureImage,
                          });
                          setShowModal(true);
                          setIsVariantEnabled(Boolean(product.variants && product.variants.length)); // Set variant toggle based on existing data
                        }}
                        className="bg-yellow-500 text-white p-2 rounded hover:bg-yellow-600 flex items-center justify-center"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDeleteProduct(product)}
                        className="bg-red-500 text-white p-2 rounded hover:bg-red-600 flex items-center justify-center"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-30">
          <div className="relative bg-white rounded-lg p-6 w-full max-w-md shadow-lg overflow-y-auto max-h-[90vh]">
            {/* Loader for creating or operation loading */}
            {(isCreating || operationLoading) && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                }}
              >
                <div
                  style={{
                    border: '16px solid #f3f3f3', /* Light grey */
                    borderTop: '16px solid #3498db', /* Blue */
                    borderRadius: '50%',
                    width: '120px',
                    height: '120px',
                    animation: 'spin 2s linear infinite',
                  }}
                />
              </div>
            )}
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-xl text-red-500 hover:text-red-700"
              onClick={() => setShowModal(false)}
            >
              &times;
            </button>
            <h2 className="text-2xl font-semibold mb-4">{editProduct ? 'Update' : 'Create'} Product</h2>
            <div className="mb-4">
              <label className="block mb-1">Product Name</label>
              <input
                name="productName"
                value={formValues.productName}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                placeholder="Product Name"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Category</label>
              <select
                name="cid"
                value={formValues.cid}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">Select Category</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.cid}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-1">Super Category (Optional)</label>
              <select name="scid" value={formValues.scid} onChange={handleInputChange} className="w-full p-2 border rounded">
                <option value="">Select Super Category</option>
                {superCategories.map((superCat) => (
                  <option key={superCat.id} value={superCat.id}>
                    {superCat.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-1">Feature Image</label>
              {formValues.featureImageUrl && (
                <div className="relative mb-2">
                  <img src={formValues.featureImageUrl} alt="Feature" className="w-16 h-16 object-cover rounded" />
                  <button
                    className="absolute -top-2 -right-2 text-red-500 hover:text-red-700"
                    onClick={handleRemoveFeatureImage}
                  >
                    <FaTimesCircle />
                  </button>
                </div>
              )}
              <input
                name="featureImageFile"
                type="file"
                onChange={handleFileChange}
                className="w-full block"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Multiple Images</label>
              <input
                name="multipleImages"
                type="file"
                multiple
                onChange={handleFileChange}
                className="w-full block"
              />
            </div>
            {formValues.previousMultipleImages.map((image, index) => (
              <div key={index} className="relative">
                <img src={image} alt={`Previous ${index}`} className="w-16 h-16 object-cover rounded" />
                <button
                  className="absolute -top-2 -right-2 text-red-500 hover:text-red-700"
                  onClick={() => handleRemovePreviousImage(index)}
                >
                  <FaTimesCircle />
                </button>
              </div>
            ))}

            <div className="mb-4">
              <label className="block mb-1">Variants</label>
              <div className="flex items-center mb-4">
                <span className="mr-2">Enable Variants</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isVariantEnabled}
                    onChange={handleVariantToggle}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              {isVariantEnabled && formValues.variants.map((variant, index) => (
                <div key={index} className="flex space-x-2 mb-2">
                    <input
                    name="price"
                    type="number"
                    value={variant.price}
                    onChange={(e) => handleVariantChange(index, e)}
                    className="w-1/3 p-2 border rounded"
                    placeholder="Price"
                  />
                  <input
                    name="quantity"
                    type="number"
                    value={variant.quantity}
                    onChange={(e) => handleVariantChange(index, e)}
                    className="w-1/3 p-2 border rounded"
                    placeholder="Qty"
                  />
                
                  <select
                    name="unit"
                    value={variant.unit}
                    onChange={(e) => handleVariantChange(index, e)}
                    className="w-1/3 p-2 border rounded"
                  >
                    <option value="">Unit</option>
                    <option value="ml">ml</option>
                    <option value="l">l</option>
                    <option value="gm">gm</option>
                    <option value="kg">kg</option>
                  </select>
                  {formValues.variants.length > 1 && (
                    <button
                      onClick={() => removeVariant(index)}
                      className="bg-red-500 text-white p-2 rounded hover:bg-red-600 flex items-center justify-center"
                    >
                      <FaTimesCircle />
                    </button>
                  )}
                </div>
              ))}
              {isVariantEnabled && (
                <button
                  onClick={addVariant}
                  className="bg-green-500 text-white p-2 rounded shadow hover:bg-green-600 flex items-center justify-center"
                >
                  <FaPlus className="mr-2" /> Add Variant
                </button>
              )}
            </div>
            {!isVariantEnabled && (
              <>
                <div className="mb-4">
                  <label className="block mb-1">Product Price</label>
                  <input
                    name="price"
                    type="number"
                    value={formValues.price}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="Price"
                    disabled={isVariantEnabled}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Sale Price (optional)</label>
                  <input
                    name="salePrice"
                    type="number"
                    value={formValues.salePrice}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="Sale Price (optional)"
                    disabled={isVariantEnabled}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Stock</label>
                  <input
                    name="stock"
                    type="number"
                    value={formValues.stock}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="Stock"
                    disabled={isVariantEnabled}
                  />
                </div>
              </>
            )}
            <div className="mb-4">
              <label className="block mb-1">Description</label>
              <textarea
                name="description"
                value={formValues.description}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                placeholder="Description"
              ></textarea>
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleCreateOrUpdateProduct}
                className="bg-blue-500 text-white p-2 rounded shadow hover:bg-blue-600"
              >
                {editProduct ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;