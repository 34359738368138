import React, { useEffect, useState } from 'react';
import { db } from '../../../../../utils/firebaseconfig'; // Ensure db is imported
import { collection, query, onSnapshot, doc, getDocs } from 'firebase/firestore';
import Swal from 'sweetalert2';
import { FiSearch } from 'react-icons/fi'; // Import search icon from react-icons

const Customer = () => {
  const [users, setUsers] = useState([]);
  const [expandedUserId, setExpandedUserId] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // New state for search query

  // Fetch all users from Firestore
  useEffect(() => {
    const usersQuery = query(collection(db, 'user'));
    const unsubscribe = onSnapshot(usersQuery, (snapshot) => {
      const usersList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersList);
    });

    // Clean up listener
    return () => unsubscribe();
  }, []);

  // Fetch user orders from Firestore
  const fetchUserOrders = async (userId) => {
    const ordersCollection = collection(db, `user/${userId}/orders`);
    const ordersSnapshot = await getDocs(ordersCollection);
    const orders = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSelectedOrders(orders);
    setExpandedUserId(userId); // Expand the clicked user
  };

  // Show order details in a modal
  const showOrderDetails = (orderId) => {
    setSelectedOrderId(orderId);
    setShowOrderModal(true); // Show modal with order details
  };

  // Close modals
  const closeOrderModal = () => {
    setShowOrderModal(false);
    setSelectedOrderId(null); // Reset selected order
  };

  // Find selected order for modal display
  const selectedOrder = selectedOrders.find(order => order.id === selectedOrderId);

  // Filter users based on search query
  const filteredUsers = users.filter(user =>
    (user.name && user.name.toLowerCase().includes(searchQuery.toLowerCase())) || 
    (user.Number && user.Number.includes(searchQuery)) || 
    (user.address && user.address.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Customer Details Page</h1>
      
      {/* Search bar */}
      <div className="mb-4 flex items-center">
        <FiSearch className="text-gray-500 mr-2" />
        <input
          type="text"
          placeholder="Search by name, number, or address..."
          className="p-2 border border-gray-300 rounded w-full"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {filteredUsers.length === 0 ? (
        <p>No customers found.</p>
      ) : (
        <div className="space-y-4">
          {filteredUsers.map((user) => (
            <div key={user.id} className="bg-white shadow-md rounded-lg p-4">
              <h2 className="text-xl font-bold">Customer: {user.name}</h2>
              <p><strong>Number:</strong> {user.Number}</p>
              <p><strong>Address:</strong> {user.address}</p>
              <p><strong>City:</strong> {user.city}</p>
              <p><strong>Zip:</strong> {user.zip}</p>
              <p><strong>Last Active:</strong> {user.lastActive?.toDate().toLocaleString()}</p>
              <button
                onClick={() => fetchUserOrders(user.id)}
                className="mt-2 p-2 bg-blue-500 text-white rounded"
              >
                View Orders
              </button>
              
              {/* Show order dates when user is expanded */}
              {expandedUserId === user.id && (
                <div className="mt-4 space-y-2">
                  {selectedOrders.map(order => (
                    <div
                      key={order.id}
                      className="cursor-pointer text-blue-600 underline"
                      onClick={() => showOrderDetails(order.id)}
                    >
                      {new Date(order.createdAt.seconds * 1000).toLocaleDateString()} - Click to view details
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Modal for showing order details */}
      {showOrderModal && selectedOrder && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Order Details</h2>
              <button onClick={closeOrderModal} className="bg-red-500 text-white px-4 py-2 rounded">
                Close
              </button>
            </div>

            <div className="border p-4 rounded-lg shadow-md mb-4">
              <h2 className="text-lg text-black font-bold mb-2">Order ID: {selectedOrder.id}</h2>
              <p><strong>Order Date:</strong> {new Date(selectedOrder.createdAt.seconds * 1000).toLocaleString()}</p>
              <p><strong>Payment Mode:</strong> {selectedOrder.paymentMode}</p>
              <p><strong>Status:</strong> {selectedOrder.status}</p>

              <h2 className="text-lg text-black font-bold mt-4">Product Details:</h2>
              <div className="overflow-y-auto max-h-60">
                {selectedOrder.products.map((product, index) => (
                  <div key={index} className="flex border p-4 rounded-lg items-start bg-gray-50 shadow-md mb-2">
                    <img
                      src={product.featureImage}
                      alt={product.productName}
                      className="w-24 h-24 object-cover rounded-lg mr-4"
                    />
                    <div className="flex-1">
                      <h4 className="text-lg font-semibold">{product.productName}</h4>
                      <p>Quantity: {product.quantity}</p>
                      <p>Total Price: {product.totalPrice}</p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Table for summary */}
              <table className="w-full mt-4 border-t border-gray-200">
                <thead>
                  <tr>
                    <th className="text-left p-2">Sr No</th>
                    <th className="text-left p-2">Product Name</th>
                    <th className="text-left p-2">Qty</th>
                    <th className="text-left p-2">Price</th>
                    <th className="text-left p-2">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder.products.map((product, index) => (
                    <tr key={index}>
                      <td className="p-2">{index + 1}</td>
                      <td className="p-2">{product.productName}</td>
                      <td className="p-2">{product.quantity}</td>
                      <td className="p-2">₹{product.totalPrice}</td>
                      <td className="p-2">₹{product.quantity * product.totalPrice}</td>
                    </tr>
                  ))}
                  {/* Calculate total */}
                  <tr className="border-t font-bold">
                    <td colSpan="4" className="p-2">Net Total:</td>
                    <td className="p-2">
                    ₹{selectedOrder.products.reduce((acc, product) => acc + (product.quantity * product.totalPrice), 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Customer;