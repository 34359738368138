import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { collection, setDoc, doc, getDocs, deleteDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../../../../utils/firebaseconfig';
import Loader from '../../../../../pages/loader';

const SuperCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [superCategories, setSuperCategories] = useState([]);



  // Fetch all super categories
  const fetchSuperCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'superCategories'));
      const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSuperCategories(data);
    } catch (error) {
      console.error('Error fetching super categories:', error.message);
    }
  };

  useEffect(() => {
    fetchSuperCategories();
  }, []);

  // Function to handle saving a super category
  const saveSuperCategory = async (title, subtitle) => {
    try {
      const scid = `scid-${String(superCategories.length + 1).padStart(2, '0')}`;
      await setDoc(doc(db, 'superCategories', scid), {
        title,
        subtitle,
        scid,
        timestamp: Timestamp.fromDate(new Date()),
        
      });
      Swal.fire('Saved!', 'Super category has been saved.', 'success');
      fetchSuperCategories();
    } catch (error) {
      console.error('Error saving super category:', error.message);
      Swal.fire('Error!', 'There was a problem saving the super category.', 'error');
    }
  };

  // Function to handle deleting a super category
  const deleteSuperCategory = async (id) => {
    try {
      await deleteDoc(doc(db, 'superCategories', id));
      Swal.fire('Deleted!', 'Super category has been deleted.', 'success');
      fetchSuperCategories();
    } catch (error) {
      console.error('Error deleting super category:', error.message);
      Swal.fire('Error!', 'There was a problem deleting the super category.', 'error');
    }
  };

 

  // Open the modal for adding or editing super categories
  const openSuperCategoryModal = async (superCategory) => {
    const existingPidsList = superCategory ? superCategory.pidsList : [];
    
    const { value: result } = await Swal.fire({
      title: superCategory ? 'Edit Super Category' : 'Create Super Category',
      html: `
        <input id="title" class="swal2-input" placeholder="Title" value="${superCategory ? superCategory.title : ''}">
        <input id="subtitle" class="swal2-input" placeholder="Subtitle" value="${superCategory ? superCategory.subtitle : ''}">
        <div id="product-selection" class="swal2-input">
          ${products.map(p => `
            <div>
              <input type="checkbox" id="${p.id}" value="${p.id}" ${existingPidsList.includes(p.id) ? 'checked' : ''}>
              <label for="${p.id}">${p.id}</label>
            </div>
          `).join('')}
        </div>
      `,
      confirmButtonText: 'Save',
      showCancelButton: true,
      preConfirm: () => {
        const title = Swal.getPopup().querySelector('#title').value;
        const subtitle = Swal.getPopup().querySelector('#subtitle').value;
        const pidsList = Array.from(Swal.getPopup().querySelectorAll('#product-selection input:checked')).map(input => input.value);
        return { title, subtitle, pidsList };
      }
    });

    if (result) {
      if (superCategory) {
        // Update existing super category
        await setDoc(doc(db, 'superCategories', superCategory.id), {
          title: result.title,
          subtitle: result.subtitle,
          pidsList: result.pidsList,
        });
        Swal.fire('Updated!', 'Super category has been updated.', 'success');
      } else {
        // Create new super category
        await saveSuperCategory(result.title, result.subtitle, result.pidsList);
      }
      fetchSuperCategories();
    }
  };

  return (
    <div>
      <button
        className="bg-gradient-to-r from-blue-500 to-teal-500 text-white py-2 px-4 rounded mb-4"
        onClick={() => openSuperCategoryModal(null)}
      >
        Create Super Category
      </button>

      {isLoading && <Loader />}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {superCategories.map(sc => (
          <div key={sc.id} className="relative border p-2 rounded shadow-lg flex items-center">
            <div className="flex-grow">
              <h3 className="text-xl font-bold">{sc.title}</h3>
              <p className="text-sm">{sc.subtitle}</p>
            </div>
            <div className="absolute top-0 right-0 mt-2 mr-2 flex space-x-2">
              <button
                className="bg-yellow-500 text-white py-1 px-2 rounded"
                onClick={() => openSuperCategoryModal(sc)}
              >
                Edit
              </button>
              <button
                className="bg-red-500 text-white py-1 px-2 rounded"
                onClick={() => deleteSuperCategory(sc.id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuperCategory;
