import React, { useEffect, useState } from 'react';
import { db } from '../../../utils/firebaseconfig'; // Ensure db is imported
import { collection, query, where, onSnapshot, doc, updateDoc, getDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [selectedDeliveryBoy, setSelectedDeliveryBoy] = useState(null);

  useEffect(() => {
    // Real-time listener for orders
    const unsubscribeOrders = () => {
      console.log('Setting up real-time listener for pending orders...');
      const usersQuery = query(collection(db, 'user'));

      const userListener = onSnapshot(usersQuery, (usersSnapshot) => {
        const allOrders = [];

        usersSnapshot.forEach(userDoc => {
          const userOrdersCollection = collection(db, `user/${userDoc.id}/orders`);
          const ordersQuery = query(userOrdersCollection, where('status', 'in', ['pending', 'process'])); // Listen to pending and process orders

          const ordersListener = onSnapshot(ordersQuery, (ordersSnapshot) => {
            const ordersList = ordersSnapshot.docs.map(doc => ({
              userId: userDoc.id,
              customerName: userDoc.data().name, // Fetch customer name
              customerNumber: userDoc.data().Number, // Fetch customer number
              customerAddress: userDoc.data().address, // Fetch customer address
              id: doc.id,
              ...doc.data(),
            }));
            setOrders(prevOrders => {
              const ordersMap = new Map(prevOrders.map(o => [o.id, o]));
              ordersList.forEach(order => ordersMap.set(order.id, order));
              return Array.from(ordersMap.values());
            });
          });

          // Clean up listener for each user's orders
          return () => ordersListener();
        });
      });

      // Clean up listener when users data changes
      return () => userListener();
    };

    // Real-time listener for delivery boys
    const unsubscribeDeliveryBoys = () => {
      console.log('Setting up real-time listener for delivery boys...');
      const deliveryBoysQuery = query(collection(db, 'deliveryBoy'));
      const deliveryBoysListener = onSnapshot(deliveryBoysQuery, (deliveryBoysSnapshot) => {
        const deliveryBoysData = deliveryBoysSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
        setDeliveryBoys(deliveryBoysData);
      });

      // Clean up listener when delivery boys data changes
      return () => deliveryBoysListener();
    };

    // Start listeners
    const cleanupOrders = unsubscribeOrders();
    const cleanupDeliveryBoys = unsubscribeDeliveryBoys();

    // Clean up listeners on component unmount
    return () => {
      cleanupOrders();
      cleanupDeliveryBoys();
    };
  }, []);

  const handleExpand = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  const handleSendToDeliveryBoy = async (order) => {
    if (!selectedDeliveryBoy) {
      Swal.fire({
        icon: 'warning',
        title: 'Select Delivery Boy',
        text: 'Please select a delivery boy before sending the order.',
      });
      return;
    }
  
    try {
      // Update the order status to "dispatch"
      const orderDocRef = doc(db, `user/${order.userId}/orders`, order.id);
      await updateDoc(orderDocRef, {
        status: 'dispatch',
        deliveryBoyId: selectedDeliveryBoy,
      });
  
      // Fetch the delivery boy's name
      const deliveryBoyDocRef = doc(db, 'deliveryBoy', selectedDeliveryBoy);
      const deliveryBoyDoc = await getDoc(deliveryBoyDocRef); // Use getDoc to fetch the document
      const deliveryBoyName = deliveryBoyDoc.exists() ? deliveryBoyDoc.data().name : 'Unknown';
  
      // Format the message
      const productLines = order.products.map((product, index) => `
        ${index + 1}. ${product.productName}
           Qty: ${product.quantity}
           Price: ₹ ${product.totalPrice}
      `).join('\n');
  
      const message = `Thank you for your order!\n\n` +
        `Order ID: ${order.id}\n\n` +
        `Product Details:\n` +
        `${productLines}\n\n` +
        `Net Price: ₹ ${order.products.reduce((sum, product) => sum + product.totalPrice, 0)}\n\n` +
        `Your delivery partner is ${deliveryBoyName}.\n\n` +
        `The order will be delivered soon to ${order.customerAddress}.`;
  
      // Prepare WhatsApp URL
      const customerNumber = order.customerNumber.replace(/\D/g, ''); // Remove non-numeric characters
      const whatsappUrl = `https://wa.me/${customerNumber}?text=${encodeURIComponent(message)}`;
  
      // Open WhatsApp with the pre-filled message
      window.open(whatsappUrl, '_blank');
  
      Swal.fire({
        icon: 'success',
        title: 'Order Sent',
        text: 'Order has been sent to the delivery boy successfully and a message has been sent to the customer.',
      });
  
      // Remove the order from the UI
      setOrders(prevOrders => prevOrders.filter(o => o.id !== order.id));
    } catch (error) {
      console.error('Error sending order:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while sending the order.',
      });
    }
  };
  

  const handleCheckboxChange = (orderId, productIndex) => {
    setOrders(prevOrders => prevOrders.map(order => 
      order.id === orderId
        ? {
            ...order,
            products: order.products.map((product, index) =>
              index === productIndex ? { ...product, checked: !product.checked } : product
            ),
          }
        : order
    ));
  };

  const allProductsChecked = (order) => order.products.every(product => product.checked);

  const handleCancelOrder = async (orderId) => {
    try {
      const orderDocRef = doc(db, `user/${orders.find(o => o.id === orderId).userId}/orders`, orderId);
      await updateDoc(orderDocRef, { status: 'cancelled' });
  
      // Show success message
      Swal.fire('Order Cancelled', 'The order has been cancelled successfully.', 'success').then(() => {
        // Refresh the page
        window.location.reload();
      });
    } catch (error) {
      console.error('Error cancelling order:', error);
      Swal.fire('Error', 'An error occurred while cancelling the order.', 'error');
    }
  };
  
  const handleProcessOrder = async (orderId) => {
    try {
      const orderDocRef = doc(db, `user/${orders.find(o => o.id === orderId).userId}/orders`, orderId);
      await updateDoc(orderDocRef, { status: 'process' });
      setExpandedOrderId(orderId);
      Swal.fire('Order Processed', 'The order status has been updated to Process.', 'success');
    } catch (error) {
      console.error('Error processing order:', error);
      Swal.fire('Error', 'An error occurred while processing the order.', 'error');
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Order Page</h1>
      {orders.length === 0 ? (
        <p>No pending orders found.</p>
      ) : (
        orders.map(order => (
          <div key={order.id} className="bg-white shadow-md rounded-lg mb-4 p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Order ID: {order.id}</h2>
              {order.status === 'pending' ? (
                <div className="flex space-x-2">
                  <button
                    onClick={() => Swal.fire({
                      title: 'Are you sure?',
                      text: "Do you want to cancel this order?",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, cancel it!',
                      cancelButtonText: 'No, keep it'
                    }).then(result => {
                      if (result.isConfirmed) {
                        handleCancelOrder(order.id);
                      }
                    })}
                    className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    No
                  </button>
                  <button
                    onClick={() => handleProcessOrder(order.id)}
                    className="bg-green-500 text-white px-4 py-2 rounded"
                  >
                    Yes
                  </button>
                </div>
              ) : (
                <button 
                  onClick={() => handleExpand(order.id)}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {expandedOrderId === order.id ? 'Collapse' : 'Expand'}
                </button>
              )}
            </div>
            {expandedOrderId === order.id && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">Customer Details</h3>
                <p className="mb-2"><strong>Name:</strong> {order.customerName}</p>
                <p className="mb-2"><strong>Number:</strong> {order.customerNumber}</p>
                <p className="mb-4"><strong>Address:</strong> {order.customerAddress}</p>
                <p className="mb-4"><strong>Payment Mode:</strong> {order.paymentMode}</p>
                <p className="mb-4"><strong>Status:</strong> {order.status}</p>
                <h3 className="text-lg font-semibold mb-2">Products</h3>
                {order.products ? (
                  <div className="space-y-4">
                    {order.products.map((product, index) => (
                      <div key={index} className="border p-4 rounded-lg flex flex-col sm:flex-row items-start sm:items-center bg-gray-50 shadow-md">
                        <img
                          src={product.featureImage}
                          alt={product.productName}
                          className="w-24 h-24 object-cover rounded-lg mb-4 sm:mb-0 sm:mr-4"
                        />
                        <div className="flex-1">
                          <h4 className="text-lg font-semibold break-words">{product.productName}</h4>
                          <p>Quantity: {product.quantity}</p>
                          <p>Total Price: {product.totalPrice}</p>
                        </div>
                        <input
                          type="checkbox"
                          checked={product.checked || false}
                          onChange={() => handleCheckboxChange(order.id, index)}
                          className="ml-auto"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No products found for this order.</p>
                )}
                {allProductsChecked(order) && (
                  <div className="mt-4">
                    <select
                      value={selectedDeliveryBoy || ''}
                      onChange={(e) => setSelectedDeliveryBoy(e.target.value)}
                      className="border px-4 py-2 rounded w-full sm:w-auto"
                    >
                      <option value="" disabled>Select Delivery Boy</option>
                      {deliveryBoys.map(boy => (
                        <option key={boy.id} value={boy.id}>
                          {boy.name}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={() => handleSendToDeliveryBoy(order)}
                      className="bg-green-500 text-white px-4 py-2 rounded mt-2"
                    >
                      Send to Delivery Boy
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default Order;