import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Thumb from './features/thumbnail/thumb';
import SuperCategory from './features/superCategory/SuperCategory';
import PinsPage from './features/adresses/pin';
import MinValuePage from './features/minValue/minValue';
import HeroText from './features/heroText/hero';
import DeliveryBoyManagement from '../../deliveryBoy/boyDetails';
import Customer from './features/customerDetails/customer';
import { FaArrowLeft } from 'react-icons/fa'; 
import OfferMsg from './features/offerMsg/offerMsg';

const DetailPage = () => {
  const { section } = useParams();
  const navigate = useNavigate();

  const renderContent = () => {
    switch (section) {
      case 'thumbnail':
        return <Thumb />;
      case 'supercategory':
        return <SuperCategory />;
      case 'pins':
        return <PinsPage />;
      case 'minvalue':
        return <MinValuePage />;
      case 'hero':
        return <HeroText />;
      case 'deliveryBoy':
        return <DeliveryBoyManagement />;
      case 'customer':
        return <Customer />;
      case 'offerMsg':
        return <OfferMsg />;
      default:
        return <div>Page not found</div>;
    }
  };

  return (
    <div className="p-4">
      <button
        onClick={() => navigate('/main/settings')}
        className="mb-4 p-2 bg-blue-500 text-white rounded flex items-center"
      >
        <FaArrowLeft className="mr-2" /> {/* Add the icon with a margin */}
        Back to Settings
      </button>
      {renderContent()}
    </div>
  );
};

export default DetailPage;
