import React from 'react';

const Loader = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="relative w-16 h-16">
      <div className="absolute inset-0 border-4 border-t-blue-500 border-white rounded-full animate-spin"></div>
    </div>
  </div>
);

export default Loader;
