import React, { useState } from 'react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import Thumb from './features/thumbnail/thumb';
import SuperCategory from './features/superCategory/SuperCategory';
import PinsPage from './features/adresses/pin';
import MinValuePage from './features/minValue/minValue';
import HeroText from './features/heroText/hero';
import DeliveryBoyManagement from '../../deliveryBoy/boyDetails';
import Customer from './features/customerDetails/customer';
import OfferMsg from './features/offerMsg/offerMsg';

const Settings = () => {
  const [selected, setSelected] = useState('');
  const navigate = useNavigate();

  const handleSelection = (path) => {
    setSelected(path);
    navigate(`/main/settings/${path}`);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">Settings Page</h1>

      {/* Navigation Buttons in 3-column grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
        <button
          onClick={() => handleSelection('thumbnail')}
          className={`p-2 text-sm rounded-md text-white font-semibold text-center bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 transition-all duration-300 ${selected === 'thumbnail' ? 'ring-2 ring-yellow-300' : ''}`}
        >
          Thumbnail Area
        </button>
        <button
          onClick={() => handleSelection('supercategory')}
          className={`p-2 text-sm rounded-md text-white font-semibold text-center bg-gradient-to-r from-pink-500 to-red-500 hover:from-pink-600 hover:to-red-600 transition-all duration-300 ${selected === 'supercategory' ? 'ring-2 ring-yellow-300' : ''}`}
        >
          Super Category Management
        </button>
        <button
          onClick={() => handleSelection('pins')}
          className={`p-2 text-sm rounded-md text-white font-semibold text-center bg-gradient-to-r from-yellow-500 to-orange-500 hover:from-yellow-600 hover:to-orange-600 transition-all duration-300 ${selected === 'pins' ? 'ring-2 ring-yellow-300' : ''}`}
        >
          Address Pins
        </button>
        <button
          onClick={() => handleSelection('minvalue')}
          className={`p-2 text-sm rounded-md text-white font-semibold text-center bg-gradient-to-r from-teal-400 to-cyan-500 hover:from-teal-500 hover:to-cyan-600 transition-all duration-300 ${selected === 'minvalue' ? 'ring-2 ring-yellow-300' : ''}`}
        >
          Minimum Value
        </button>
        <button
          onClick={() => handleSelection('hero')}
          className={`p-2 text-sm rounded-md text-white font-semibold text-center bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 transition-all duration-300 ${selected === 'hero' ? 'ring-2 ring-yellow-300' : ''}`}
        >
          Hero Text
        </button>
        <button
          onClick={() => handleSelection('deliveryBoy')}
          className={`p-2 text-sm rounded-md text-white font-semibold text-center bg-gradient-to-r from-red-400 to-pink-500 hover:from-red-500 hover:to-pink-600 transition-all duration-300 ${selected === 'deliveryBoy' ? 'ring-2 ring-yellow-300' : ''}`}
        >
          Delivery Boy Management
        </button>
        <button
          onClick={() => handleSelection('customer')}
          className={`p-2 text-sm rounded-md text-white font-semibold text-center bg-gradient-to-r from-yellow-400 to-orange-500 hover:from-orange-500 hover:to-red-600 transition-all duration-300 ${selected === 'customer'? 'ring-2 ring-yellow-300' : ''}`}
         >
          Customer Details
          </button>
          <button
          onClick={() => handleSelection('offerMsg')}
          className={`p-2 text-sm rounded-md text-white font-semibold text-center bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 transition-all duration-300 ${selected === 'offerMsg'? 'ring-2 ring-yellow-300'
            : ''}`}
        >
          Offer Message Management
        </button>
      </div>

      {/* Define Nested Routes */}
      <Routes>
        <Route path="thumbnail" element={<Thumb />} />
        <Route path="supercategory" element={<SuperCategory />} />
        <Route path="pins" element={<PinsPage />} />
        <Route path="minvalue" element={<MinValuePage />} />
        <Route path="hero" element={<HeroText />} />
        <Route path="deliveryBoy" element={<DeliveryBoyManagement />} />
        <Route path="customer" element={<Customer />} />
      </Routes>
    </div>
  );
};

export default Settings;
