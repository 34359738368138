import React from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { FaChartBar, FaThLarge, FaBoxOpen, FaClipboardList, FaCog } from 'react-icons/fa'; 
import Analytics from '../../components/adminComps/analytics/analytics'; 
import Category from '../../components/adminComps/category/Category';
import Products from '../../components/adminComps/products/Products';
import Orders from '../../components/adminComps/order/order'; 
import Settings from '../../components/adminComps/Settings/Settings'; 
import WelcomePage from '../../components/welcome/welcome';

const BottomNavBar = () => {
  const location = useLocation();
  const getActiveClass = (path) => location.pathname.startsWith(path) ? 'text-yellow-300' : 'text-white';

  return (
    <div className="fixed bottom-0 w-full bg-gray-900 text-white shadow-lg">
      <div className="flex justify-around items-center py-3">
        <NavItem to="/main/orders" Icon={FaClipboardList} label="Orders" isActiveClass={getActiveClass('/main/orders')} />
        <NavItem to="/main/category" Icon={FaThLarge} label="Category" isActiveClass={getActiveClass('/main/category')} />
        <NavItem to="/main/products" Icon={FaBoxOpen} label="Products" isActiveClass={getActiveClass('/main/products')} />
        <NavItem to="/main/analytics" Icon={FaChartBar} label="Analytics" isActiveClass={getActiveClass('/main/analytics')} />
        <NavItem to="/main/settings" Icon={FaCog} label="Settings" isActiveClass={getActiveClass('/main/settings')} />

      </div>
    </div>
  );
};

const NavItem = ({ to, Icon, label, isActiveClass }) => (
  <Link 
    to={to} 
    className={`flex flex-col items-center transform transition-transform hover:scale-110 duration-300 ease-in-out ${isActiveClass}`}
  >
    <Icon className="text-2xl mb-1" />
    <span className="text-sm">{label}</span>
    <div className={`w-1 h-1 rounded-full mt-1 transition-all ${isActiveClass === 'text-yellow-300' ? 'bg-yellow-300' : 'bg-transparent'}`}></div>
  </Link>
);

const MainPage = () => {
  const location = useLocation();

  return (
    <div className="min-h-screen pb-16">
      <Routes>
        {/* Show WelcomePage only when on /main */}
        {location.pathname === '/main' && <Route path="/" element={<WelcomePage />} />}
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/category" element={<Category />} />
        <Route path="/products" element={<Products />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/settings/*" element={<Settings />} />
      </Routes>
      <BottomNavBar />
    </div>
  );
};

export default MainPage;
