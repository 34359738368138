import React, { useState, useEffect } from 'react';
import { db } from '../../../../../utils/firebaseconfig';
import { doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';

const PinsPage = () => {
  const [pins, setPins] = useState([]);
  const [newPin, setNewPin] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingPinValue, setEditingPinValue] = useState('');
  const documentId = 'city-heart-addressPins'; // replace with your document ID
  const pinsDocRef = doc(db, 'adminSettings', documentId);

  // Fetch pins from Firestore
  useEffect(() => {
    const fetchPins = async () => {
      try {
        const docSnap = await getDoc(pinsDocRef);
        if (docSnap.exists()) {
          setPins(docSnap.data().pins || []);
        } else {
          // Create the document if it does not exist
          await setDoc(pinsDocRef, { pins: [] });
          setPins([]);
        }
      } catch (error) {
        console.error("Error fetching pins:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch pins. Please try again later.',
        });
      }
    };

    fetchPins();
  }, []);

  // Add a new pin
  const handleAddPin = async () => {
    if (newPin) {
      const updatedPins = [...pins, newPin];
      try {
        await updateDoc(pinsDocRef, { pins: updatedPins });
        setPins(updatedPins);
        setNewPin('');
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Pin added successfully!',
        });
      } catch (error) {
        console.error("Error adding pin:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add pin. Please try again later.',
        });
      }
    }
  };

  // Start editing a pin
  const handleEditPin = (index) => {
    setEditingIndex(index);
    setEditingPinValue(pins[index]);
  };

  // Save the edited pin
  const handleSaveEdit = async () => {
    if (editingIndex !== null && editingPinValue) {
      const updatedPins = pins.map((pin, index) => (index === editingIndex ? editingPinValue : pin));
      try {
        await updateDoc(pinsDocRef, { pins: updatedPins });
        setPins(updatedPins);
        setEditingIndex(null);
        setEditingPinValue('');
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Pin updated successfully!',
        });
      } catch (error) {
        console.error("Error updating pin:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update pin. Please try again later.',
        });
      }
    }
  };

  // Delete a pin
  const handleDeletePin = async (index) => {
    const updatedPins = pins.filter((_, i) => i !== index);
    try {
      await updateDoc(pinsDocRef, { pins: updatedPins });
      setPins(updatedPins);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Pin deleted successfully!',
      });
    } catch (error) {
      console.error("Error deleting pin:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete pin. Please try again later.',
      });
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-semibold mb-4">Manage Pins</h1>
      <div className="mb-4">
        <input
          type="text"
          value={newPin}
          onChange={(e) => setNewPin(e.target.value)}
          placeholder="Add a new pin"
          className="p-2 border rounded"
        />
        <button onClick={handleAddPin} className="bg-blue-500 text-white p-2 rounded ml-2">
          Add Pin
        </button>
      </div>
      <table className="w-full border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-2 border">Sr No</th>
            <th className="p-2 border">Pin</th>
            <th className="p-2 border">Action</th>
          </tr>
        </thead>
        <tbody>
          {pins.map((pin, index) => (
            <tr key={index}>
              <td className="p-2 border text-center">{index + 1}</td>
              <td className="p-2 border">
                {editingIndex === index ? (
                  <input
                    type="text"
                    value={editingPinValue}
                    onChange={(e) => setEditingPinValue(e.target.value)}
                    className="p-1 border rounded"
                  />
                ) : (
                  pin
                )}
              </td>
              <td className="p-2 border text-center">
                {editingIndex === index ? (
                  <>
                    <button
                      onClick={handleSaveEdit}
                      className="bg-green-500 text-white p-2 rounded mr-2"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setEditingIndex(null)}
                      className="bg-gray-500 text-white p-2 rounded"
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => handleEditPin(index)}
                      className="bg-yellow-500 text-white p-2 rounded mr-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeletePin(index)}
                      className="bg-red-500 text-white p-2 rounded"
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PinsPage;
